<template>
  <div>
    <b-card-group deck>
      <b-card
          header="Chỉnh sửa kịch bản"
          header-tag="header"
      >
        <b-form v-if="show">
          <div class="row">
            <div class="col-md-6">
              <b-form-group
                  id="campaign_title"
                  label="Chọn sự kiện (*):"
                  label-for="input-1"
              >
                <b-form-input disabled :value="eventSelected.name">

                </b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6" >
              <b-form-group
                  id="campaign_title"
                  label-for=""
                  label="code sự kiện(*):"
              >
                <b-form-input
                    id="input-1"
                    disabled
                    :value="eventSelected.code"
                    placeholder="code sự kiện"
                    :class="[{ 'is-invalid': form.errors.has('event_code') }]"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <b-form-group
                  id="client_id"
                  label="Kiểu phát quà (*):"
                  label-for="input-1"

              >
                <b-form-select v-model="form.type" :class="[{ 'is-invalid': form.errors.has('type') }]">
                  <option :value="1">Phát ngẫu nhiên theo số lượng</option>
                  <option :value="2">Phát ngẫu nhiên theo tỷ lệ</option>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Hình ảnh (nếu có):"  >
                <div style="display: flex" >
                  <div style="height: 30px; margin-right: 20px" v-show="form.img.length >0">
                    <img
                        style="width: 38px; height: 38px"
                        :src="form.img"
                        alt=""
                    />
                  </div>
                  <b-form-file
                      @change="onImageChange($event)"
                      :required="form.img.length > 0?false:true"
                      placeholder="chọn hình ảnh"
                      accept="image/*"
                      type="file"
                  ></b-form-file>
                </div>
              </b-form-group>
            </div>
          </div>
          <div class="row" style="margin-bottom: 20px;">
            <div class="col-md-1">
              Kích hoạt
            </div>
            <div class="col-md-3">

              <b-form-checkbox
                  id="checkbox-1"
                  v-model="form.status"
                  :value="1"
                  :unchecked-value="0"
                  size="lg"
              >

              </b-form-checkbox>
            </div>
          </div>
          <hr>
          <h3 style="color:#0066FF;padding: 10px 0;">
            Cài đặt quà tặng
          </h3>
          <div class="row">
            <div class="col-md-6">
              <b-form-group
                  id="input-g"
                  label="Chọn combo voucher quà tặng (*)"
                  label-for="input-g"

              >

                <multiselect
                    v-model="ComboItems"
                    @input="onChangeComboItems"
                    :options="script.event.combos"
                    :multiple="true"
                    track-by="title"
                    label="title"
                    id="display_box"
                    :close-on-select="true"
                    :clear-on-select="true"
                    placeholder="Chọn combo"
                ></multiselect>

              </b-form-group>
            </div>
          </div>

          <div class="row">
            <b-table :items="ComboItems" :fields="fields">
              <template #cell(name)="data">
                <b-form-input disabled type="text" :value="data.value"></b-form-input>
              </template>
              <template  style="display: none"  #cell(id)="data">
                <b-form-input  v-model="ComboItems[data.index].id"  :value="data.value" ></b-form-input>
              </template>
              <template #cell(quantity)="data">
                <b-form-input
                    type="number"
                    :disabled="ComboItems[data.index].status !=1"
                    v-model="ComboItems[data.index].quantity"
                    :value="data.value"
                ></b-form-input>
              </template>
              <template #cell(max_in_day)="data">
                <b-form-input
                    type="number"
                    :disabled="ComboItems[data.index].status !=1"
                    v-model="ComboItems[data.index].max_in_day"
                    :value="data.value"
                ></b-form-input>
              </template>
              <template #cell(ratio)="data" v-if="form.type ===2">
                <b-form-input
                    type="number"
                    :disabled="ComboItems[data.index].status !=1"
                    v-model="ComboItems[data.index].ratio"
                    :value="data.value"
                ></b-form-input>
              </template>
              <template #cell(status)="data" >
                <span v-if="data.value ==1" class="btn btn-sm btn-success ">ON</span>
                <span v-else class="btn btn-sm btn-danger"> OFF </span>
              </template>
              <template #cell(action)="data">
                <b-btn class="btn btn-danger" @click="deleteItem(data.index)" ><i  class="flaticon2-trash"></i>Xóa</b-btn>
              </template>
            </b-table>

          </div>

          <div class="row">
            <div class="col-md-12">
              <b-button variant="warning"  style="margin-right: 10px;" @click="onUpdate">Cập nhật</b-button>
              <router-link
                  :to="'/combo-voucher/script'"
              >
                <b-button variant="outline-primary">Hủy</b-button>
              </router-link>

            </div>
          </div>

        </b-form>
      </b-card>


    </b-card-group>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { Form } from 'vform';
import Common from "@/core/mixins/common";
import Multiselect from "vue-multiselect";
import Vue from "vue";

import RepositoryFactory from "@/core/repositories/repositoryFactory";
const WalletCampaignRepository = RepositoryFactory.get("comboVoucher");
const WalletRunRepository = RepositoryFactory.get("WalletRun");

Vue.component("multiselect", Multiselect);
export default {
  name: "FormComboVoucherCampaign",
  mixins: [Common],
  components: {},
  data() {
    return {
      form: new Form({
        id: '',
        event_id: '',
        type: 1,
        img: '',
        status: 1
      }),
      show: true,
      is_edit: false,
      eventSelected: {},
      events: [],
      images: [],
      test: [],

      fields: [],
      ComboItems: [
      ],
      ComboItemsList: [
      ],
      script: {
        event:{
          combos:[]
        }
      }

    };

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Kịch bản phát quà", route: "/combo-voucher/script" },
      { title: "Sửa kịch bản " },
    ]);
  },

  methods: {
    onChangeComboItems(){
      this.convertData();
    },
    deleteItem(index){
      this.ComboItems.splice(index,1);
    },
    verifyRatio(){
      if (this.form.type ===2){
        let totalRatio = 0;
        this.ComboItems.forEach((item)=>{
          if (item.status ===1){
            totalRatio +=parseInt(item.ratio);
          }
        })
        if (totalRatio ===100){
          return true
        }
        return false;
      }
      return true;
    },
    onSelectEvent(){
      let that = this;
      this.events.forEach((event)=>{
        if (event.id == that.form.event_id){
          that.eventSelected = event;
          that.form.event_code = event.code;
          this.innitComboItems();
        }
      })
    },
    innitComboItems(){
      let that = this;
      that.ComboItems = [];
      that.ComboItemsList = [];
      this.script.combo_voucher_items.forEach((comboAdded)=>{
        that.eventSelected.combos.forEach((combo,index)=>{
          if (combo.status ==1){
            that.eventSelected.combos[index].$isDisabled = false;
          }else {
            that.eventSelected.combos[index].$isDisabled = true;
          }
          if (comboAdded.combo_voucher_id ==combo.id){
            let cbItem = {
              id:comboAdded.id,
              combo_voucher_id:comboAdded.combo_voucher_id,
              title:combo.title,
              quantity:comboAdded.quantity,
              max_in_day:comboAdded.max_in_day,
              ratio:comboAdded.ratio,
              status:combo.status,
            }
            that.ComboItems.push(cbItem)
          }

        })

      })
    },
    onImageChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      let fileSize = files[0].size / 1024 / 1024; // in MiB
      if (fileSize> 2){
        this.$notify({
          group: "foo",
          type: "error",
          title:"Chọn ảnh không vượt quá 2MB",
        });
        return false;
      }
      this.images = files[0];
      this.uploadMedia();
    },
    uploadMedia: async function () {
      this.$bus.$emit("show-loading", true);
      const formData = new FormData();
      formData.append("file", this.images);
      WalletRunRepository.uploadMedia(formData)
          .then((response) => {
            this.$bus.$emit("show-loading", false);
            if (1 === response.data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: response.data.message,
              });
            } else {
                this.form.img = response.data.data.link;
            }
          })
          .catch((response) => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          });
    },
    convertData() {
      let newobj= []
      this.ComboItems.forEach((item)=>{
        if (item.combo_voucher_id){
          newobj.push(item);
        }else {
          newobj.push({
            combo_voucher_id:item.id,
            title:item.title,
            quantity:'',
            max_in_day:'',
            ratio:'',
            status:item.status,
          });
        }
      })
      this.ComboItems = newobj;
    },
    async loadScripts (id) {
      try {
        this.$bus.$emit("show-loading", true);
        let response = await WalletCampaignRepository.getScript(id);
        this.$bus.$emit("show-loading", false);
        if (response.data.error_code === 0) {
          let data = response.data.data;
          this.eventSelected = data.event;
          this.script = data;
          this.form.status = data.status;
          //this.form.img = data.img;
          this.form.id = data.id;
          if (data.type==="RATIO"){
            this.form.type =2;
          }else {
            this.form.type = 1;
          }
          this.innitComboItems();
        } else {
          this.$bus.$emit("show-loading", false);
          this.$notify({
            group: "foo",
            type: "error",
            title: response.data.message,
          });
        }
      } catch (error) {
        this.$notify({
          group: "foo",
          type: "error",
          title: error,
        });
      }
    },
    isEmpty(value){
      return (typeof value === "undefined" || value === null || value === '');
    },
    verifyQuantity(){
      let that = this;
      let count = 0;
      this.ComboItems.forEach((item,index)=>{
            if (item.status ===1){
              let item_quantity = that.isEmpty(item.quantity)?0: parseInt(item.quantity);
              if ( typeof item_quantity !='number' || item_quantity < 1 ){
                that.ComboItems[index].error = true;
                count ++;
              }else {
                that.ComboItems[index].error = false;
              }
            }
      })
      return count ===0;
    },
    onUpdate () {
      if (this.ComboItems.length == 0){
        this.$notify({
          group: "foo",
          type: "error",
          title: 'Không thể bỏ trống quà tặng',
        });
        return false;
      }
      if (!this.verifyRatio()){
        this.$notify({
          group: "foo",
          type: "error",
          title: 'Vui lòng kiểm tra lại tỷ lệ trổng số tỉ lệ không lớn hơn 100%',
        });
        return false;
      }
      if (!this.verifyQuantity()){
        this.$notify({
          group: "foo",
          type: "error",
          title: 'Vui lòng kiểm tra lại số lượng quà phát ra',
        });
        return false;
      }
      this.$bus.$emit("show-loading", true);
      this.form.data_combo = JSON.stringify(this.ComboItems);
      this.form.submit('post', '/combo-voucher/script/'+this.form.id)
          .then(({data}) => {
            this.$bus.$emit("show-loading", false);
            if (1 === data.error_code) {
              this.$notify({
                group: "foo",
                type: "error",
                title: data.message,
              });
            } else {
              this.$bus.$emit("show-loading", false);
              this.$notify({
                group: "foo",
                type: "success",
                title: data.message,
              });
              this.$router.push({ name: "combo_voucher_script" });
            }
          })
          .catch((error) => {
            this.$bus.$emit("show-loading", false);
            this.$notify({
              group: "foo",
              type: "error",
              title: error,
            });
          })
    },
    innitFields(){
      if (this.form.type ===2){
        this.fields = [
          { key: "id", label: "id", thClass: 'd-none', tdClass: 'd-none'},
          { key: "title", label: "Tên combo voucher"},
          { key: "quantity", label: "Tổng số lượng quà phát ra" },
          { key: "max_in_day", label: "Số lượng quà tối đa theo ngày" },
          { key: "ratio", label: "Tỷ lệ" },
          { key: "status", label: "Trạng thái" },
          { key: "action", label: "Tùy chọn" },
        ]
      }else {
        this.fields = [
          { key: "id", label: "id", thClass: 'd-none', tdClass: 'd-none' },
          { key: "title", label: "Tên combo voucher"},
          { key: "quantity", label: "Tổng số lượng quà phát ra" },
          { key: "max_in_day", label: "Số lượng quà tối đa theo ngày" },
          { key: "status", label: "Trạng thái" },
          { key: "action", label: "Tùy chọn" },
        ]
      }

    }
  },
  created() {
    this.innitFields();
    if (this.$route.params.id) {
      this.is_edit = true;
      this.loadScripts(this.$route.params.id);
    }
  },
  watch: {
    'form.type'() {
      this.innitFields();
    }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

